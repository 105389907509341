import React, { useContext, useEffect, useRef } from 'react';
import { AppContext } from '@/store/context';
import { CaseStudyData } from '@/mock/SiteData';
import SEO from '@/components/shared/SearchEngineOptimizations/SearchEngineOptimizations';
import useTriggerLightTheme from '@/hooks/useTriggerLightTheme';
import NotFoundPage from '@/pages/404';
import { CaseStudyBanner } from '@/components/shared/case-study-banner';
import { TitleCopy } from '@/components/shared/title-copy';
import { ImageGallery } from '@/components/shared/image-gallery';
import { Separator } from '@/components/ui/separator';
import { TechnologiesList } from '@/components/shared/technologies-list';
import { AnimatedTagCopy } from '@/components/shared/animated-tag-copy';
import { cn } from '@/lib/utils';
import useScrollAnimation from '@/hooks/useScrollAnimation';
import WordpressLogo from '@/assets/images/technologies/wordpress.webp';
import GatsbyLogo from '@/assets/images/technologies/gatsby.webp';
import SpringLogo from '@/assets/images/technologies/spring.inline.svg';
import { useTransitionAnimation } from '@/hooks/useTransitionAnimation';

const ANIMATED_CLASSNAME = 'animated fadeInUp';

const caseStudy = CaseStudyData.find((m) => m.id === 'get-real');

const GotMilk = () => {
  const { setCurrentPage } = useContext(AppContext);

  const { tlState, defaultAnimationOffset } = useTransitionAnimation();

  const projectWhiteSectionRef = useRef<HTMLDivElement>(null);

  useTriggerLightTheme(
    projectWhiteSectionRef.current,
    defaultAnimationOffset,
    defaultAnimationOffset,
    tlState,
    0.5,
  );

  // TODO: we'll to think a better approach to avoid calling several times the same webhook
  const titleCopyFirst = useScrollAnimation();

  const titleCopySecond = useScrollAnimation();

  const titleCopyThird = useScrollAnimation();

  const imageGalleryFirst = useScrollAnimation();

  const imageGallerySecond = useScrollAnimation();

  const technologiesListRef = useScrollAnimation();

  const animatedTagCopyRef = useScrollAnimation();

  const lightSeparatorRef = useScrollAnimation();

  const darkSeparatorRef = useScrollAnimation();

  useEffect(() => {
    setCurrentPage('Case Study');
  }, []);

  if (!caseStudy) {
    return <NotFoundPage />;
  }

  const {
    hero: { title, heroDescriptionText, bgImg, projectSection },
    pageDescription,
    externalLink,
  } = caseStudy || {};

  return (
    <main className="page page--case-study">
      <CaseStudyBanner
        pageDescription={pageDescription}
        descriptionText={heroDescriptionText}
        title={title}
        bgImg={bgImg}
        projectSection={projectSection}
        paralaxTranslateY={[20, -20]}
        externalLink={externalLink}
      />
      <div className="case-study-content">
        <div ref={projectWhiteSectionRef} className="flex flex-col gap-36 bg-white py-36">
          <TitleCopy
            ref={titleCopyFirst}
            title="The Client"
            description="The California Milk Processor Board is a nonprofit marketing board funded by California dairy processors and administered by the California Department of Food and Agriculture. It is most well known for its Got Milk? advertising campaign."
            className={ANIMATED_CLASSNAME}
          />
          <ImageGallery
            ref={imageGalleryFirst}
            className={cn('px-4 md:px-8 xl:px-0', ANIMATED_CLASSNAME)}
            images={[
              { src: '/images/case-studies/getreal/mock-1.webp', alt: 'image1' },
              { src: '/images/case-studies/getreal/mock-2.webp', alt: 'image2' },
            ]}
          />
          <TitleCopy
            ref={titleCopySecond}
            title="The Need"
            description={`They came up with an idea for their "Got Milk?" campaign that needed to be implemented in less than two months. Consisted of a California map that shows all social media posts from Tiktok, Instagram, and Twitter that includes their campaign hashtags. They wanted that solution to be fully configurable, from content creators, to cities and to hashtags that were used to pull up the posts from social media.`}
            className={ANIMATED_CLASSNAME}
          />
          <ImageGallery
            ref={imageGallerySecond}
            className={cn('px-4 md:px-8 xl:px-0', ANIMATED_CLASSNAME)}
            images={[{ src: '/images/case-studies/getreal/scroll.gif', alt: 'gif1' }]}
          />
          <TitleCopy
            ref={titleCopyThird}
            title="The Solution"
            description={`It has three important applications. In the first place, we built a Spring Java server app to integrate with Data365 API to pull up the posts by location, hashtags, and content creators' usernames.\n\nWe parse that data and expose it by an API to be pulled from a Gatsby.js application that was running inside a headless WordPress site to make it fully customizable. This front-end application integrates with the Mapbox library to show the California map so the user will be able to zoom in and out and navigate through the different cities to see the posts that match the campaign hashtags.\n\nThe client is able to modify some Gatsby data from WordPress along with some Spring Java Middleware configuration, like hashtags to be pulled, location, and content creators' usernames along with the capability to add or remove posts that are pulled from the social media.`}
            className={ANIMATED_CLASSNAME}
          />
          <Separator
            ref={lightSeparatorRef}
            className={cn('-mb-36 mx-auto max-w-7xl', ANIMATED_CLASSNAME)}
          />
        </div>
        <div className="animated-copy animate-transition flex flex-col gap-36 bg-loop-black py-36">
          <TechnologiesList
            ref={technologiesListRef}
            icons={[
              <img className="w-40" src={GatsbyLogo} key="gatsby" alt="gatsby" />,
              <img className="w-40" src={WordpressLogo} key="wordpress" alt="wordpress" />,
              <SpringLogo key="spring" />,
            ]}
            description=""
            className={{
              section: cn(ANIMATED_CLASSNAME, 'lg:items-center'),
              text: 'animate-transition-text text-white',
            }}
          />
          <div className="block">
            <AnimatedTagCopy
              ref={animatedTagCopyRef}
              tag="the result"
              className={{
                section: cn(ANIMATED_CLASSNAME, 'mx-auto px-4 md:px-8 xl:px-0'),
                tag: 'animate-transition-text',
              }}
              animatedCopy={{
                id: 'animated-copy-1',
                text: 'We delivered a website that, in addition to its <w>refinement<w> and <w>simplicity<w>, reinforces the values and concepts of the brand.',
                trigger: '.animated-copy',
                animationStart: 'top 30%',
                className: 'mx-auto',
              }}
            />
            <AnimatedTagCopy
              className={{ section: 'mx-auto mt-12 px-4 md:px-8 xl:px-0' }}
              animatedCopy={{
                id: 'animated-copy-2',
                text: 'Easy to access, <w>dynamic<w> and <w>agile<w>, a website that met the needs of the client and that its users will enjoy browsing. Go ahead! We invite you to discover a site with a <w>narrative<w> like no other.',
                trigger: '.animated-copy',
                revealDelay: 0.75,
                animationStart: 'top 30%',
                className: 'mx-auto',
              }}
            />
          </div>
          <Separator
            ref={darkSeparatorRef}
            className={cn('mx-auto max-w-[1064px] bg-gray-dark', ANIMATED_CLASSNAME)}
          />
        </div>
      </div>
    </main>
  );
};

export default GotMilk;

export const Head = () => {
  if (caseStudy) {
    return (
      <SEO title="Loop3 Studio - Got Milk" description={`${caseStudy.hero.heroDescriptionText}`} />
    );
  }
};
